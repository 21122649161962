const pics = [
  {
    offset: 0.05,
    xOffset: 0.7,
    url: "/pictures/pizza.jpg",
    z: 2
  },
  {
    offset: 0.145,
    xOffset: 0.75,
    url: "/pictures/Bistroleoraden20.03_064.jpg",
    z: 1
  },
  {
    offset: 0.28, //what?
    xOffset: 0.65,
    url: "/pictures/rott.jpg",
    z: 1
  },
  {
    offset: 0.01,
    xOffset: 1.4,
    url: "/pictures/Bistroleoraden20.03_065.jpg",
    z: 1
  },
  {
    offset: 0.125,
    xOffset: 1.55,
    url: "/pictures/vitt.jpg",
    z: 2
  },
  {
    offset: 0.21,
    xOffset: 1.45,
    url: "/pictures/Bistroleoraden20.03_026.jpg",
    z: 1
  },
  {
    offset: 0.315,
    xOffset: 1.25,
    url: "/pictures/beer.jpg",
    z: 1
  },
  {
    offset: 0.395,
    xOffset: 1.5,
    url: "/pictures/chark.jpg",
    z: 1
  },,  {
    offset: 0.415,
    xOffset: 0.65,
    url: "/pictures/bollar.jpg",
    z: 1
  },
]

export default pics
