import React, { useCallback, useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useSpring, animated, config, interpolate } from 'react-spring'
import './styles.css'
import pics from "./data/pics"
import map from "./map.jpg"
import UE from "./UE.png"

const scale = 0.42
function App() {
  const s = useWindowSize();
  let fadeDelay = 150;
  const overlay = useRef()
  const picsWrapper = useRef()
  const [showPics, setShowPics] = useState()
  const [data, setData] = useState()
  const [posts, setPosts] = useState()
  const [fields, setFields] = useState()
  const [categories, setCategories] = useState()
  const [page, setPage] = useState(0)
  useEffect(() => {
    fetch('http://cms.bistroleoparden.se/?rest_route=/wp/v2/pages/')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data)
      });
    fetch('http://bistroleoparden.se/cms/wp-json/wp/v2/posts?custom_per_page=200')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPosts(data)
      });
    fetch('http://bistroleoparden.se/cms/wp-json/wp/v2/categories?per_page=100')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCategories(data)
      });
  }, [])
  console.log(data)
  console.log(categories)
  const [linkActive, setLinkActive] = useState(null)
  const [showBack, setShowBack] = useState(false)
  const [showContent, setShowContent] = useState(false)
  const [open, setOpen] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const { contentOpacity } = useSpring({ contentOpacity: (showContent || mobileMenu) ? 1 : 0 })
  const { backOpacity } = useSpring({ backOpacity: showBack ? 1 : 0 })
  const { mainOpacity } = useSpring({ mainOpacity: !showBack && showPics ? 1 : 0 })
  const { yTranslate } = useSpring({ yTranslate: !showBack && showPics ? 'translateY(50px)' : 'translateY(0px)' })
  const { logoTop } = useSpring({ logoTop: open ? '5vh' : '10vh', config: config.slow })
  const onScrolls = useCallback(e => { setPage(Math.floor(window.scrollY / ((window.innerWidth * 4 * (scale-0.02)) + window.innerWidth * 0.5))) }, []);
  useEffect(() => { onScrolls() }, [s.width])
  useEffect(() => { window.addEventListener('scroll', onScrolls) }, [])
  useEffect(() => {
    setTimeout(() => {
      setShowPics(true)
    }, 500)
  }, [])
  const contentStyle = {
    opacity: contentOpacity,
  }
  const backStyle = {
    opacity: backOpacity,
  }
  const logoStyle = {
    top: logoTop,
  }
  const picsStyle = {
    opacity: mainOpacity,
    transform: yTranslate
  }
  function clickedMenu(link) {
    mobileMenu && setMobileMenu(false)
    if (open && (link == null || link == linkActive)) { // hide back
      setShowContent(false)
      setShowBack(false)
      picsWrapper.current.style.display = 'block'

      setTimeout(() => {
        setLinkActive(null)
        window.scrollTo(0, 0)
        setOpen(false)
      }, fadeDelay)
    } else {
      setOpen(true)
      setShowContent(false)

      setTimeout(() => {
        setLinkActive(link)
        window.scrollTo(0, 0)
        setShowContent(true)
        setShowBack(true)
        picsWrapper.current.style.display = 'none'
      }, fadeDelay)
    }

  }
  return (
    <>
      <animated.div style={contentStyle} className={"cover" + (mobileMenu ? " open" : "") + (!linkActive ? ' start' : '')} />
      <div onScroll={onScrolls} className={"overlay " + (mobileMenu ? "open" : "")} ref={overlay}>
        {mobileMenu && <a href="https://www.instagram.com/bistroleoparden/">
          <svg
            class="instagramMobile"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            fill="#0c307c"
          >
            <path d="M352 0H160C71.648 0 0 71.648 0 160v192c0 88.352 71.648 160 160 160h192c88.352 0 160-71.648 160-160V160C512 71.648 440.352 0 352 0zm112 352c0 61.76-50.24 112-112 112H160c-61.76 0-112-50.24-112-112V160C48 98.24 98.24 48 160 48h192c61.76 0 112 50.24 112 112v192z"></path>
            <path d="M256 128c-70.688 0-128 57.312-128 128s57.312 128 128 128 128-57.312 128-128-57.312-128-128-128zm0 208c-44.096 0-80-35.904-80-80 0-44.128 35.904-80 80-80s80 35.872 80 80c0 44.096-35.904 80-80 80z"></path>
            <circle cx="393.6" cy="118.4" r="17.056"></circle>
          </svg>
        </a>}
        <div className={"mobileMenu" + (mobileMenu ? " open" : "")}>
          <span className={linkActive == 4 ? 'active' : ''} onClick={() => clickedMenu(4)}>Leoparden</span>
          <span className={linkActive == 1 ? 'active' : ''} onClick={() => clickedMenu(1)}>Meny</span>
          <span className={linkActive == 2 ? 'active' : ''} onClick={() => clickedMenu(2)}>Öppet</span>
          <span className={linkActive == 3 ? 'active' : ''} onClick={() => clickedMenu(3)}>Hitta hit</span>
          <span className={linkActive == 6 ? 'active' : ''} onClick={() => clickedMenu(6)}>Take away</span>
          <span className={linkActive == 7 ? 'active' : ''} onClick={() => clickedMenu(7)}>Stora sällskap</span>
        </div>
        <div className="mobileBurger" onClick={() => setMobileMenu(bool => !bool)}>
          <img className="mobileBurger" src="./menu.svg" />
        </div>
        <animated.img src="./Orange_Leoparden2.png" className="logo" style={logoStyle} onClick={() => linkActive ? clickedMenu(linkActive) : null} />
        {data && <animated.div style={contentStyle} className="meny">
          {linkActive == 1 && <Menu data={data} posts={posts} fields={fields} categories={categories} />}
          {linkActive == 2 && <Oppet data={data} />}
          {linkActive == 3 && <Hitta data={data} />}
          {linkActive == 4 && <Main data={data} />}
          {linkActive == 6 && <Avhamtning data={data} />}
          {linkActive == 7 && <Storre data={data} />}
        </animated.div>}
        <animated.div className={"picsWrapper " + ((!showBack && showPics) ? 'show' : '')} ref={picsWrapper}>
          {pics.map((pic, index) =>
            <Pic data={pic} key={index} page={page} open={open} mobile />
          )}
          {pics.map((pic, index) =>
            <Pic data={pic} key={index} page={page + 1} open={open} mobile={false} />
          )}
        </animated.div>
        <animated.div style={backStyle} className="backStrip">
          <div className="leftCurves"></div>
          <div className="rightCurves"></div>
        </animated.div>
        <div className="menu">
          <div className="menuWrapper">
            <span className={linkActive == 4 ? 'active' : ''} onClick={() => clickedMenu(4)}>Leoparden</span>
            <span className={linkActive == 1 ? 'active' : ''} onClick={() => clickedMenu(1)}>Meny</span>
            <span className={linkActive == 2 ? 'active' : ''} onClick={() => clickedMenu(2)}>Öppet</span>
            <span className={linkActive == 3 ? 'active' : ''} onClick={() => clickedMenu(3)}>Hitta hit</span>
            <span className={linkActive == 6 ? 'active' : ''} onClick={() => clickedMenu(6)}>Take away</span>
            <span className={linkActive == 7 ? 'active' : ''} onClick={() => clickedMenu(7)}>Stora sällskap</span>
            <a href="https://www.instagram.com/bistroleoparden/">
              <svg
                className="instagram"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 512 512"
                version="1.1"
                viewBox="0 0 512 512"
                fill="#0c307c"
              >
                <path d="M352 0H160C71.648 0 0 71.648 0 160v192c0 88.352 71.648 160 160 160h192c88.352 0 160-71.648 160-160V160C512 71.648 440.352 0 352 0zm112 352c0 61.76-50.24 112-112 112H160c-61.76 0-112-50.24-112-112V160C48 98.24 98.24 48 160 48h192c61.76 0 112 50.24 112 112v192z"></path>
                <path d="M256 128c-70.688 0-128 57.312-128 128s57.312 128 128 128 128-57.312 128-128-57.312-128-128-128zm0 208c-44.096 0-80-35.904-80-80 0-44.128 35.904-80 80-80s80 35.872 80 80c0 44.096-35.904 80-80 80z"></path>
                <circle cx="393.6" cy="118.4" r="17.056"></circle>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

function Menu({ posts, fields, categories }) {
  const column1Mat = ['Snacks', 'Grillat & lagat']
  const column2Mat = ['Pizza', 'Sött']
  const column1Dryck = ['Mousserande', 'Vita', 'Cocktails']
  const column2Dryck = ['Röda', 'Fatöl', 'Flasköl', 'Övrig dryck']
  return (
    <>
      <h1>Mat</h1>
      <div className="section">
        <div className="column">
          {column1Mat.map((cat) =>
            <Category catName={cat} category={categories.find(element => element.name.replace('&amp;', '&') == cat).id} posts={posts} fields={fields} />
          )}
        </div>
        <div className="column">
          {column2Mat.map((cat) =>
            <Category catName={cat} category={categories.find(element => element.name.replace('&amp;', '&') == cat).id} posts={posts} fields={fields} />
          )}
        </div>
      </div>
      <h1>Dryck</h1>
      <div className="section">
        <div className="column">
          {column1Dryck.map((cat) =>
            <Category catName={cat} category={categories.find(element => element.name.replace('&amp;', '&') == cat).id} posts={posts} fields={fields} />
          )}
        </div>
        <div className="column">
          {column2Dryck.map((cat) =>
            <Category catName={cat} category={categories.find(element => element.name.replace('&amp;', '&') == cat).id} posts={posts} fields={fields} />
          )}
        </div>
      </div>
    </>
  )
}

function Category({ catName, category, posts, fields }) {
  const currPosts = posts.filter(post => post.categories[0] == category);
  const dots = ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
  return (
    <>
      <div className="category">{catName}</div>
      {currPosts.map((post) =>
        <div className="dish" key={post.title.rendered + Math.random()}>
          <span className="title">{post.title.rendered.replace(/#038;/gi, '')}</span>
          <span className="desc">{post.acf.description}</span>
          <span className="dots">{dots}</span>
          <span className="price">{post.acf.price}</span>
        </div>
      )}
    </>
  )
}


function Pic({ data, page, mobile }) {
  const s = useWindowSize();
  const x = -(s.width / 8) + (data.xOffset * window.innerWidth / 2);
  const y = (page * window.innerWidth * 4 * (scale+0.1)) + (data.offset * window.innerWidth * 4);

  const width = s.width / 4;
  const divStyle = {
    left: x,
    top: y,
    width: width,
    zIndex: data.z
  }
  return (
    <img className={"pic" + (!mobile ? " hideMobile" : '')} src={data.url} style={divStyle} />
  )
}

function Oppet({ data }) {
  return (
    <>
      <div className="section">
        <div className="center topcenter" dangerouslySetInnerHTML={{ __html: data[3].content.rendered }} />
      </div>
    </>
  )
}
function Avhamtning({ data }) {
  return (
    <>
      <div className="section">
        <div className="center topcenter" dangerouslySetInnerHTML={{ __html: data[0].content.rendered }} />
      </div>
    </>
  )
}
function Storre({ data }) {
  return (
    <>
      <div className="section">
        <div className="topcenter main" dangerouslySetInnerHTML={{ __html: data[2].content.rendered }} />
      </div>
    </>
  )
}


function Main({ data }) {
  console.log(data)
  return (
    <>
      <div className="section">
        <div className="center main" dangerouslySetInnerHTML={{ __html: data[4].content.rendered }} />
      </div>
    </>
  )
}
function Hitta({ data }) {
  return (
    <>
     <div className="section">
        <div className="center topcenter main" dangerouslySetInnerHTML={{ __html: data[1].content.rendered }} />
      </div>
    </>
  )
}

// Hook
function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

ReactDOM.render(<App />, document.getElementById('root'))
